<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <!-- <b-dropdown
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <i class="flaticon-search"></i>
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: Search -->

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="
            btn btn-icon btn-clean btn-dropdown btn-lg
            mr-1
            pulse pulse-primary
          "
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <i class="flaticon2-notification text-primary"></i>
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-900px">
        <div
          class="
            card card-custom card-stretch
            text-white
            bg-info
            gutter-b
            overflow-auto
          "
          style="max-height: 400px"
        >
          <!--begin::Header-->
          <div class="card-header border-0 pt-5 text-white">
            <div class="card-title align-items-start flex-column text-white">
              <h6>Notifikasi</h6>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <!--begin::Table-->
            <div class="table-responsive" v-if="Notifikasi.length">
              <table class="table table-vertical-center text-white">
                <thead>
                  <tr>
                    <th class="p-0" style="width: 50px">No</th>
                    <th class="p-0 text-center">Date</th>
                    <th class="p-0 text-center">Pesan</th>
                    <th class="p-0 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <template> -->
                  <tr v-for="(item, i) in Notifikasi" v-bind:key="i">
                    <td class="pl-0 py-4">
                      <div class="symbol symbol-30 symbol-light mr-1">
                        <span class="symbol-label">
                          {{ i + 1 }}
                        </span>
                      </div>
                    </td>
                    <td class="text-center">
                      <span class="font-weight-bolder d-block font-size-lg">
                        <span v-if="item[0].tanggal">
                          {{ moment(item[0].tanggal) }}
                        </span>
                        <span v-else> {{ moment(item[0].created_at) }} </span>
                      </span>
                    </td>
                    <td class="pl-0">
                      <span class="font-weight-bolder d-block font-size-lg">
                        {{ item[0].pesan }}
                      </span>
                    </td>
                    <td class="text-center">
                      <a :href="item[0].url" target="_blank" class="btn btn-icon btn-light btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <i class="flaticon-eye text-primary"></i>
                          <!--end::Svg Icon-->
                        </span>
                      </a>
                    </td>
                  </tr>
                  <!-- </template> -->
                </tbody>
              </table>
              <!-- <b-pagination
                pills
                v-model="currentPageNotifikasi"
                @input="paginateNotifikasi"
                :total-rows="totalRowsNotifikasi"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-2"
              ></b-pagination> -->
            </div>
            <div v-else>
              <h3 class="mb-10">Anda tidak memiliki notifikasi.</h3>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
          <!--end::Advance Table Widget 2-->
        </div>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Shopping/Cart3.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownMyCart></KTDropdownMyCart>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: My Cart -->

    <!--begin: Quick panel toggle -->
    <!-- <KTQuickPanel></KTQuickPanel> -->
    <!--end: Quick panel toggle -->

    <!--begin: Language bar -->
    <!-- <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div> -->
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
// import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
// import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
// import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
// import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue'
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from '@/core/services/i18n.service.js'

import * as moment from 'moment'
import 'moment/locale/id'
import ApiService from '@/core/services/api.service'

export default {
  name: 'KTTopbar',
  data() {
    return {
      languageFlag: '',
      languages: i18nService.languages,

      Notifikasi: [],
      totalRowsNotifikasi: 0,
      totalPerPageNotifikasi: 0,
      currentPageNotifikasi: 1,
    }
  },
  components: {
    // KTSearchDefault,
    // KTDropdownNotification,
    // KTDropdownQuickAction,
    // KTDropdownMyCart,
    // KTDropdownLanguage,
    KTQuickUser,
    // KTQuickPanel
  },
  created() {
    this.loadDataNotifikasi()
  },
  methods: {
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD LTS').format('dddd, LL')
    },
    paginateNotifikasi() {
      this.loadDataNotifikasi()
    },
    async loadDataNotifikasi() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get('/home/notifikasi')
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.Notifikasi = data.data
            // self.totalPerPageNotifikasi = data.data.data.length;
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage()
      }).flag
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged()
    },
  },
}
</script>
