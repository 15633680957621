<template>
  <ul class="menu-nav">
    <li class="menu-item">
      <div class="menu-link">
        <b-form-input v-model="form.search" @input="search" placeholder="Search..."></b-form-input>
      </div>
      <!-- <a :href="href" class="menu-link" @click="navigate">
        <i class="menu-icon flaticon2-architecture-and-city text-light"></i>
        <span class="menu-text">Home</span>
      </a> -->
    </li>

    <router-link
      v-if="menu.includes('home')"
      to="/home"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city text-light"></i>
          <span class="menu-text">Home</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="menu.includes('dashboard')"
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-line-chart text-light"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <li
      v-if="
        menu.includes('report') ||
          menu.includes('r create report') ||
          menu.includes('r view report only') ||
          menu.includes('r conditional report')
      "
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/report'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-multimedia-2 text-light"></i>
        <span class="menu-text"> Data Inspeksi </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-if="
              menu.includes('report') ||
                menu.includes('r create report') ||
                menu.includes('r view report only')
            "
            to="/report/index"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Report </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="menu.includes('report') || menu.includes('r conditional report')"
            to="/report/conditional_report"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Condition Monitoring </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      v-if="menu.includes('data desain') || menu.includes('data desain view only')"
      to="/datadesain"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon svg-icon-md svg-icon-light">
            <i class="flaticon2-folder"></i>
          </span>
          <span class="menu-text">Data Desain</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="menu.includes('inspection plan') || menu.includes('inspection plan view only')"
      to="/inspectionplan"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon svg-icon-md svg-icon-light">
            <i class="flaticon2-magnifier-tool"></i>
          </span>
          <span class="menu-text">Inspection Plan</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="menu.includes('to do')"
      to="/todo"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon svg-icon-md svg-icon-light">
            <i class="flaticon2-magnifier-tool"></i>
          </span>
          <span class="menu-text">To Do</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="menu.includes('penilaian resiko')"
      to="/equipment_kritis"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-pen text-light"><span></span></i>
          <span class="menu-text"> Penilaian Resiko </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="
        menu.includes('peralatan inspeksi') ||
          menu.includes('peralatan inspeksi view only') ||
          menu.includes('peralatan inspeksi peminjam')
      "
      to="/peralataninspeksi"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user-1 text-light"></i>
          <span class="menu-text">Peralatan Inspeksi</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="
        menu.includes('sertifikasi perizinan') || menu.includes('sertifikasi perizinan view only')
      "
      to="/sertifikasiperizinan"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-folder-1 text-light"></i>
          <span class="menu-text">Sertifikasi Perizinan</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="menu.includes('pengujian & kalibrasi')"
      to="/pengujiankalibrasi"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-list-3 text-light"></i>
          <span class="menu-text">Pengujian & Kalibrasi</span>
        </a>
      </li>
    </router-link>

    <li
      v-if="
        menu.includes('kualifikasi las') ||
          menu.includes('welder') ||
          menu.includes('kualifikasi welder') ||
          menu.includes('pengelasan history') ||
          menu.includes('pqr') ||
          menu.includes('wps')
      "
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/kualifikasi_las'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-users text-light"></i>
        <span class="menu-text">Kualifikasi Las </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-if="menu.includes('kualifikasi las') || menu.includes('welder')"
            to="/kualifikasi_las/welder"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Welder </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="menu.includes('kualifikasi las') || menu.includes('kualifikasi welder')"
            to="/kualifikasi_las/kualifikasi_welder"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Kualifikasi Welder </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="menu.includes('kualifikasi las') || menu.includes('pengelasan history')"
            to="/kualifikasi_las/history"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> History </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="menu.includes('kualifikasi las') || menu.includes('pqr')"
            to="/kualifikasi_las/pqr"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> PQR </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="menu.includes('kualifikasi las') || menu.includes('wps')"
            to="/kualifikasi_las/wps"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> WPS </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="
        menu.includes('master') ||
          menu.includes('m pabrik') ||
          menu.includes('m eksekutor') ||
          menu.includes('m plant') ||
          menu.includes('m part') ||
          menu.includes('m equipment') ||
          menu.includes('m condition monitoring') ||
          menu.includes('m group point') ||
          menu.includes('m point') ||
          menu.includes('m inspection element')
      "
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/master'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-image-file text-light"></i>
        <span class="menu-text"> Master </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-if="menu.includes('m pabrik') || menu.includes('master')"
            to="/master/pabrik"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Pabrik </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="menu.includes('m eksekutor') || menu.includes('master')"
            to="/master/eksekutor"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Eksekutor </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="menu.includes('m plant') || menu.includes('master')"
            to="/master/plant"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Plant </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="menu.includes('m part') || menu.includes('master')"
            to="/master/part"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Part </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="menu.includes('m equipment') || menu.includes('master')"
            to="/master/equipment"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Equipment </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="menu.includes('m condition monitoring') || menu.includes('master')"
            to="/master/conditionmonitoring"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Condition Monitoring </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="menu.includes('m group point') || menu.includes('master')"
            to="/master/grouppoint"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Group Point </span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="menu.includes('m point') || menu.includes('master')"
            to="/master/point"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Point </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="menu.includes('setting user') || menu.includes('role') || menu.includes('users')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/user'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-users text-light"></i>
        <span class="menu-text">Setting User </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-if="menu.includes('setting user') || menu.includes('role')"
            to="/user/role"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Role </span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="menu.includes('setting user') || menu.includes('users')"
            to="/user/users"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Users </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      v-if="menu.includes('google drive')"
      to="/g-drive"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-google-drive-file text-light"><span></span></i>
          <span class="menu-text"> Google Drive </span>
        </a>
      </li>
    </router-link>

    <li
      v-if="menu.includes('logout')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
    >
      <a class="menu-link" @click="onLogout">
        <i class="menu-icon flaticon-logout text-light"></i>
        <span class="menu-text">Logout</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { LOGOUT } from '@/core/services/store/auth.module'
import ApiService from '@/core/services/api.service'
export default {
  name: 'KTMenu',
  data() {
    return {
      form: {
        search: null,
      },
      menu: [],
      allMenu: [],
      fitur: {
        Home: false,
        Dashboard: false,
        Data_desain: false,
        inspection_plan: false,
        peralatan_inspeksi: false,
        sertifikasi_perizinan: false,
        pengujian_kalibrasi: false,
        Report: {
          parent: false,
          create: false,
        },
        Master: {
          parent: false,
          pabrik: false,
          plant: false,
          part: false,
          equipment: false,
          inspection_element: false,
        },
        Setting_user: {
          parent: false,
          role: false,
          users: false,
        },
      },
    }
  },
  async created() {
    var self = this
    await ApiService.get('/user/users/getMenu') //get All Menu
      .then(({ data }) => { 
        if (data.status == 'ok') {
          var menu = data.menu
          for (let i = 0; i < menu.length; i++) {
            if (menu[i].fitur != '') {
              var fitur = JSON.parse(menu[i].fitur)
            if (fitur != null) {
              for (let j = 0; j < fitur.length; j++) {
                if (!self.allMenu.includes(fitur[j].toLowerCase())) {
                  self.allMenu.push(fitur[j].toLowerCase())
                }
              }
            }  
            }            
          }
          self.allMenu.push('logout')
          self.menu = self.allMenu
        }
      })
      .catch(response => {
        console.log(response) 
      })
      
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }))
    },
    async search() {
      let a = this.allMenu
      let term = this.form.search

      await this.timeout(10)
      if (!term) {
        this.menu.splice(0, this.menu.length)
        this.menu = a
      } else {
        let b = a.filter(item => item.toLowerCase().indexOf(term) > -1)
        this.menu = b
      }
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    },
  },
}
// console.log(data)
</script>
